<template>
  <div class="preview-attachment">
    <div class="preview-attachment-inner">
      <div class="approval-name">{{ name }}</div>
      <template v-if="type === 'Image'">
        <template v-for="(item, index) in attachmentList">
          <div class="image-wrapper">
            <div class="image-name-wrapper">
              <div>
                <span>{{ $t('common.image') + index + 1 }}</span>
              </div>
              <div class="download-all" @click="() => handleDownload(item)">
                {{ $t('common.download') }}
              </div>
            </div>
            <div class="image">
              <img :src="item"/>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <template v-for="(item, index) in attachmentList">
          <div class="attachement-wrapper">
            <div class="left-content">
              <div class="icon">
                <FileType
                    :fileType="`${getExtensionFromFileName(item)}`"
                    :size="40"
                />
              </div>
              <div class="name">
                {{ item.substring(item.lastIndexOf("/") + 1) }}
              </div>
            </div>
            <div class="right-content">
              <div class="download-all" @click="() => handleDownload(item)">
                {{ $t('common.download') }}
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import FileType from "./FileType";
import {getPreviewFile} from "@/api/preview"

export default {
  name: "Preview",
  components: {FileType},
  data() {
    return {
      name: '',
      type: '',
      attachmentList: [],
    }
  },
  mounted() {
    const query = this.$route.query;
    const processInstanceId = query.processInstanceId.toString();
    const fieldId = query.fieldId.toString();
    const routeType = query.type.toString();
    if (processInstanceId && fieldId) {
      getPreviewFile(processInstanceId, fieldId).then(res => {
        this.type = routeType
        this.name = res.data.formName
        this.attachmentList = res.data.attachmentList
      })
    }
  },
  methods: {
    handleDownload(url) {
      const filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            let a = document.createElement("a");
            a.download = filename;
            a.href = window.URL.createObjectURL(blob);
            a.onclick = () => {
              setTimeout(() => {
                window.URL.revokeObjectURL(a.href);
              }, 1500);
            };
            a.click();
            a.remove();
          });
    },
    getExtensionFromFileName(fileName) {
      let index = fileName.lastIndexOf('.')
      return fileName.substring(index + 1)
    }
  }
}
</script>

<style scoped>
.preview-attachment {
  background-color: rgb(246, 246, 247);
  height: 100vh;
  overflow: auto;
}

.preview-attachment-inner {
  max-width: 720px;
  margin: 52px auto;
}

.preview-attachment-inner .approval-name {
  opacity: 0.8;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 4px;
  color: #141f33;
}

.preview-attachment-inner .image-wrapper {
  margin-top: 32px;
}

.preview-attachment-inner .attachement-wrapper {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
}

.preview-attachment-inner .image-wrapper .image-name-wrapper {
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  color: #141f33;
  font-size: 16px;
  opacity: 0.8;
}

.preview-attachment-inner .download-all {
  display: inline-block;
  color: #37f;
  text-decoration: underline;
  cursor: pointer;
}

.preview-attachment-inner .image-wrapper .image img {
  width: 720px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
}

.preview-attachment-inner .attachement-wrapper .left-content {
  display: flex;
  width: 70%;
  align-items: center;
}

.preview-attachment-inner .attachement-wrapper .left-content .icon {
  width: 40px;
  height: 40px;
  margin: 12px;
}

.preview-attachment-inner .attachement-wrapper .left-content .name {
  margin-right: 12px;
}

.preview-attachment-inner .attachement-wrapper .right-content {
  display: flex;
  justify-content: flex-end;
}

.preview-attachment-inner .attachement-wrapper .right-content .download-all {
  margin-right: 24px;
}
</style>
