<template>
  <div class="file-type-box" :style="{ transform: scale }">
    <div
        class="file-type-folder angle"
        :style="{ background: background }">
    </div>
    <div class="file-type-name">{{ fileType }}</div>
  </div>
</template>

<script>
export default {
  name: "FileType",
  props: {
    size: {
      type: Number,
      default: 40
    },
    fileType: {
      type: String,
      default: "?"
    },
  },
  computed: {
    // // 文件类型
    // showFileType() {
    //   return this.props.fileType.toUpperCase();
    // },
    scale() {
      return `scale(${this.size / 100})`;
    },
    // 文件类型对应的颜色
    background() {
      // 生成颜色，后面加一段随机数，确保每个文件类型的颜色都不一样，且有较大的差异，避免相邻的颜色太接近
      return `linear-gradient(-135deg, transparent 1.2727em, #${this.generateColor(
          this.fileType.value + "123456"
      )} 0)`;
    }
  },
  methods: {
    intToRGB(i) {
      const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
      return "00000".substring(0, 6 - c.length) + c;
    },
    generateColor(str) {
      if (!str) str = ''
      return this.intToRGB(this.hashcode(str));
    },
    hashcode(str) {
      var hash = 0, i, chr, len;
      if (str.length === 0) return hash;
      for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }
  }
}
</script>

<style lang="scss" scoped>
.file-type-box {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  transform-origin: left top;

  .file-type-folder {
    position: relative;
    margin: auto;
    width: 80%;
    height: 100%;
    border-radius: 4px;

    &.angle::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1.8em;
      height: 1.8em;
      background: linear-gradient(
              to left bottom,
              transparent 50%,
              rgba(0, 0, 0, 0.1) 0,
              rgba(0, 0, 0, 0.1)
      ) 100% 0 no-repeat;
      transform-origin: bottom right;
      border-bottom-left-radius: inherit;
    }
  }

  .file-type-name {
    position: absolute;
    width: 80%;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 38%;
    bottom: 0;
    margin: auto;
    text-align: center;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
}
</style>

