import request from '@/api/request.js'

//查询
export function getPreviewFile(processInstanceId, filedId) {
    return request({
        url: '/ptt/getFileUrl',
        method: 'get',
        params: {processInstanceId, filedId}
    })
}
